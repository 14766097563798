


































import {Component, Prop, Vue} from 'vue-property-decorator';
import Vessel from '@/models/Vessel.model';
import {namespace} from 'vuex-class';
import {vesselStoreActions} from '@/store/vessel.store';
import {UserRole} from '@/models/User.model';

const VesselStore = namespace('vessel');

@Component({
  components: {}
})
export default class VesselTableComponent extends Vue {

  @VesselStore.Action(vesselStoreActions.UPDATE)
  public updateVessel!: (payload: { id: string, status: boolean }) => Promise<Vessel>

  @VesselStore.Action(vesselStoreActions.DELETE)
  public deleteVessel!: (payload: { id: string }) => Promise<Vessel>

  @Prop({default: () => []})
  public vessels!: Vessel[];

  @Prop({default: ''})
  public searchValue!: string;

  public tableHeaders = [{
    text: this.$t('SETTINGS.SHIPS_VIEW.TABLE.NAME'),
    value: 'name',
    sortable: true
  }, {
    text: this.$t('SETTINGS.SHIPS_VIEW.TABLE.CODE'),
    value: 'code',
    sortable: true
  }, {
    text: this.$t('SETTINGS.SHIPS_VIEW.TABLE.STATUS'),
    value: 'status',
    sortable: false
  }, {
    text: this.$t('SETTINGS.SHIPS_VIEW.TABLE.OPTIONS'),
    value: 'options',
    sortable: false,
    align: 'right'
  }];

  public async updateVesselStatus(vessel: Vessel) {
    await this.updateVessel({id: vessel.id!, status: !vessel.status!});
  }

  public async deleteVesselClicked(vessel: Vessel) {
    await this.deleteVessel({id: vessel.id!});
  }

  public get viewOnly(): boolean {
    return this.$hasRole(UserRole.PLANER)
  }
}

